/*
	Stylesheet cookies consent boxu na webu TYMA CZ
*/
.consent-box {
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  width: 100%;
  max-width: 640px;
  border-top: 1px solid #cce5f3;
  padding: 0 20px;
}
.consent-box .fieldset-box-like {
  padding: 0;
}
.consent-box .form-row {
  width: auto;
}
.consent-box .form-row__helper {
  display: block;
}
.consent-box .form-row [type=checkbox] + label {
  display: inline-block;
  font-weight: 700;
  margin: 0;
}
.consent-box [type=checkbox]:disabled + label:before,
.consent-box [type=checkbox]:disabled:checked + label:after {
  opacity: 0.5;
}
.consent-box .but {
  display: inline-block;
  width: 100%;
  margin-left: 0;
  cursor: pointer;
}
.consent-box a {
  color: #161616;
}
.consent-box [type=checkbox] + label:before {
  background-color: #fff;
}
.consent-box__wrapper {
  background-color: rgba(22, 22, 22, 0.8);
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  width: 100%;
  height: 100vh;
  z-index: 999999;
  overflow-y: scroll;
}
.consent-box__buttons-set {
  display: flex;
  flex-direction: column-reverse;
}
.consent-box__detailed {
  border: 0;
  margin: 25px 0;
  padding: 0;
}

.consent-choice__note {
  padding-left: 36px;
  font-size: 0.857rem;
}

.consent-id {
  text-align: center;
  font-size: 0.857rem;
  color: #adadad;
}
.consent-id span {
  font-family: monospace;
}

.consent-box__simple {
  text-align: right;
}

.consent-box__detailed {
  display: none;
}

/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 460px) {
  .consent-box .form-row {
    width: 350px;
  }
  .consent-box .form-row__helper {
    display: inline-block;
  }
  .consent-box__buttons-set {
    flex-direction: row;
    justify-content: space-between;
  }
  .consent-box__detailed {
    border: 1px solid #efefef;
    padding: 20px;
  }
  .consent-box .but {
    width: auto;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 500px) {
  .consent-box__buttons-set {
    display: block;
  }
  .consent-box__buttons-set .but {
    margin-left: 20px;
  }
  .consent-box .but--consent-choose {
    float: left;
    margin-left: 0;
  }
}

/*# sourceMappingURL=consent-box.css.map */